@import '~@angular/material/theming';

$app-primary: mat-palette($mat-blue, 500);
$app-accent: mat-palette($mat-green, 500);
$app-warn: mat-palette($mat-red);

$primary: mat-color($app-primary);
$accent: mat-color($app-accent);
$warn: mat-color($app-warn);

$saturday: mat-color(mat-palette($mat-blue, 50));
$sunday: mat-color(mat-palette($mat-red, 50));
$timer: mat-color(mat-palette($mat-yellow, 500));