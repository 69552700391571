
@import '~@angular/material/theming';
@include mat-core();
@import 'variables';

/*
$primary: mat-palette($mat-blue, 500);
$accent: mat-palette($mat-green, 500);
$warn: mat-palette($mat-red);*/

$theme: mat-light-theme(
    $app-primary,
    $app-accent,
    $app-warn
);

@include angular-material-theme($theme);

html, body { height: 100%; }
body { 
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background: #e2e2e2;
}

.primary-color {
    color: $primary;
}

.accent-color {
    color: $accent;
}

.warn-color {
    color: $warn;
}

.mat-card {
    margin: 14px;
}

div.mat-card-header-text {
    margin: 0 8px;
}

table {
    overflow: hidden;
    border-radius: 4px;
}

/*
.mat-drawer-container {
    background: #e2e2e2;
}*/


.refresh-icon {
    margin-bottom: 2px;
}

.spacer {
    margin-bottom: 16px;
}

.dialog-btn {
    margin-bottom: 2px;
}

.custom-snack-bar {
    border-left: 6px solid;
    border-left-color: $primary;
    color: #000000de !important;
    background-color: #FFF !important;
    .mat-simple-snackbar-action {
        color: $warn !important;
    }
}

.mat-focused .mat-form-field-required-marker {
    color: $primary;
}

.sei-mei .mat-form-field-infix {
    width: 100% !important;
    max-width: 180px;
}

.month-select .mat-form-field-infix {
    width: 116px !important;
}

.menuBack {
    background-color: rgba($color: #000000, $alpha: 0.8) !important;
}

.tutorial .mat-dialog-container {
    box-shadow: none !important;
    background: transparent !important;
    color: #FFF !important;
}

$custom-typography: mat-typography-config(
  $font-family: 'Noto Sans JP',
  $input: mat-typography-level(inherit, 1.3, 400)
);
@include mat-core($custom-typography);
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
